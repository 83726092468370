<template>
  <div
    class="adding-block"
    role="button"
    @click.prevent="$emit('click')"
  >
    <div class="adding-block__icon">
      <svg
        width="24"
        height="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fill-rule="nonzero"
          fill="none"
          :transform="iconTransform"
        >
          <circle
            stroke="#7700ff"
            fill="rgba(16, 24, 40, 0.05)"
            cx="12"
            cy="12"
            r="11.5"
          />
          <path fill="#7700ff" d="M11 7h2v10h-2z" />
          <path fill="#7700ff" d="M17 11v2H7v-2z" />
        </g>
      </svg>
    </div>
    <div class="adding-block__text">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionBtn',
  props: {
    isRemove: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconTransform() {
      return this.isRemove ? 'rotate(45 12 12)' : '';
    },
  },
};
</script>

<style scoped>
.adding-block {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
}
.adding-block__icon {
  display: inline-block;
}
.adding-block__icon svg {
  display: block;
  overflow: hidden;
}
.adding-block__text {
  font-size: 16px;
  color: #101828;
  letter-spacing: 0;
  white-space: nowrap;
  transition: color 0.2s;
}

.adding-block__text:not(:empty) {
  margin-left: 15px;
}
.adding-block:hover {
  cursor: pointer;
}
.adding-block:hover .adding-block__text {
  color: $color-primary-1-day;
}
.adding-block--red:hover .adding-block__text {
  color: red;
}
</style>
